.CircleChart{
    position: relative;
    width: 132px;
    height: 132px;
}
.CircleChart .valTf{
    display:flex;
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    top:0;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    font-family: PingFang-SC-Heavy, sans-serif;
    font-size: 38px;
    color: #FEFEFE;
}