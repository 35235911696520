/**
优   #5cd478
良   #ccd064
轻度污染   #e9953b
中度污染   #ea6060
重度污染   #f166d9
严重污染   #948585


pm2.5
优    0-35
良    36-75
轻度污染   76-115
中度污染   116-150
重度污染   151-250
严重污染   251~

pm10
          优    0-50
          良    51-150
轻度污染   151-250
中度污染   251-350
重度污染   351-420
严重污染   421~

co2
          优    0-799
          良    800-999
轻度污染   1000-1499
中度污染   1500-2419
重度污染   2420-3499
严重污染   3500~

 */


.MainPage{
    background: #fff url("./res/bg.png") 0 0 no-repeat;
    width: 1366px;
    height: 768px;
    display:flex;
    flex-direction: column;
    align-items: center;
    color: #f1f1f1;
    text-align: left;
    font-weight: 500;
    /*letter-spacing: 4px;*/
    position: relative;
    font-family: Arial, sans-serif;
}

.MainPageLeft {
}

.MainPageRight {

}

.mainTitle {
    font-family: PingFang-SC-Heavy, sans-serif;
    font-size: 26px;
    color: #FFFFFF;
    letter-spacing: 1.9px;
    position: absolute;
    left: 108px;
    top: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;

}
.mainTitle .location{
    margin-top: 2px;
}

.mainTitle .tf {
    margin-left: 10px;
    margin-right: 10px;
}
.mainTitle .logo{
    width:104px;
    height:28px;
    margin-left: 40px;
}
.roomTabBar {
    position: absolute;
    left: 67px;
    top: 122px;
    width: 596px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-image: url("./res/rectangle6.png");

    overflow:hidden;
}
.roomTabTitle{
    font-family: PingFang-SC-Heavy, sans-serif;
    font-size: 20px;
    color: #00FFD8;
    margin-left: 42px;
    padding-bottom: 2px;
}
.roomTabLi {
    margin-left: 15px;
    opacity: 0.3;
    font-family: PingFang-SC-Heavy, sans-serif;
    font-size: 22px;
    color: #00FFD8;
    display: flex;
    align-items: center;
    justify-content: center;
}
.roomTabLi.active {
    opacity: 1;
}

.pubTime {
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    margin-right: 40px;
}

.pubTimeTf {
    opacity: 0.3;
    font-family: PingFang-SC-Heavy, sans-serif;
    font-size: 18px;
    color: #00FFD8;
    text-align: right;
}

.roomTabContainerBg{
    opacity: 0.85;
    background: rgba(11,32,83,0.99);
    width: 596px;
    height: 448px;
    position: absolute;
    top:171px;
    left:67px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.SingleRoomBoard{

}

.SingleRoomTemperature{
    left:82px;
    top:193px;
    width: 545px;
    height:65px;
    padding-left: 11px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 16px;
    position: absolute;
    background: transparent url("./res/rectangle3.png") 0 0 no-repeat;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.TemperatureCol{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex:1;
    padding-left: 17px;
    padding-right: 20px;

    font-family: PingFangSC-Semibold, sans-serif;
    font-size: 20px;
    color: #FEFEFE;
}
.TemperatureValTf{
    font-family: PingFang-SC-Heavy, sans-serif;
    font-size: 32px;
    color: #FEFEFE;
}

.TemperatureIcon{
    width: 18px;
    height: 32px;
}
.PmBoard2{
    position: absolute;
    left:83px;
    top:284px;
    width: 260px;
    height: 174px;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    background-image: url("./res/pic2.png");
}
.PmBoard2.right{
    left:367px;
}
.PmBoard2.out1{
    left:748px;
}
.PmBoard2.out2{
    left:1032px;
}

.PmBoard2Title{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left:17px;
    margin-top:4px;
    font-family: PingFangSC-Semibold, sans-serif;
    font-size: 20px;
    color: #FFFFFF;
}
.PmBoard2UnitTf{
    opacity: 0.5;
    font-family: sans-serif;
    font-size: 18px;
    color: #FFFFFF;
    letter-spacing: -0.3px;
    left:130px;
    top:92px;
    position: absolute;
}
.PmBoard2Desc{
    font-family: PingFang-SC-Heavy, sans-serif;
    font-size: 22px;
    color: #2DFF61;

    display: flex;
    align-items: center;
    flex-direction: row;
    position:   absolute;
    right: 20px;
    top: 124px;
}
.PmBoard2DescIcon{
    margin-left: 10px;
    width:14px;
    height: 16px;
    animation: DeltaIconAnim infinite 1s linear;
    animation-iteration-count: 5;
}
@keyframes DeltaIconAnim {
    from {
        opacity: 0; }
    to {
        opacity: 1; }
}


.Co2Board3{
    position: absolute;
    left:83px;
    top:488px;
    width: 260px;
    height: 91px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    background-image: url("./res/pic3.png");

}

.Co2Board3.right{
    left:385px;
}
.Co2Board3.out1{
    left:748px;
}
.Co2Board3.out2{
    left:1032px;
}
.Co2Board3.out3{
    left:748px;
    top: 610px;
}
.Co2Board3.out4{
    left:1032px;
    top: 610px;
}
.Co2Board3Label{
    margin-top:11px;
    margin-left:20px;
    margin-bottom: 3px;
}
.Co2Board3Label2{
    margin-left:20px;
    margin-bottom: 9px;
}
.Co2Board3Val{
    font-family: PingFangSC-Semibold, sans-serif;
    font-size: 32px;
    color: #FEFEFE;
    top:25px;
    right:25px;
    text-align: right;
    position: absolute;

}


.Weather{
    position:absolute;
    display:flex;
    top:44px;
    left:758px;
    width: 494px;
    height: 98px;

    padding-bottom: 25px;
    padding-left: 25px;
    padding-top: 25px;
    padding-right: 25px;

    background: transparent;
    flex-direction: column;

    justify-content: space-between;
    align-items: flex-start;

    font-family: PingFang-SC-Heavy, sans-serif;
    font-size: 28px;
    color: #FFFFFF;
}
.WeatherBgImg{
    position:absolute;
    top:0;left:0;
    right:0;
    bottom: 0;
    z-index: 0;
}
.WeatherCity{
    z-index: 1;
}
.WeatherRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 1;
}
.WeatherVal{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.WeatherDescIcon{
    margin-left: 10px;
    width:43px;
    height:43px;
}
.WeatherT{
    font-size: 30px;
}

.AQIBar{
    position:absolute;
    display:flex;
    top:205px;
    left:774px;
    height: 45px;
    width: 504px;

    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 22px;
    color: #FFFFFF;
}
.AQITitle{
    display:flex;
    flex-direction: row;
    align-items: center;
}
.AQIVal{
    font-family: PingFang-SC-Heavy, sans-serif;
    font-size: 32px;
    color: #FFFFFF;
}
.AQIDesc{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: PingFang-SC-Heavy, sans-serif;
    font-size: 22px;
}

.AQIBg{
    position:absolute;
    top:63px;
    left:748px;
    height:212px;
    width:564px;
    background: transparent url("./res/rectangle5.png") 0 0 no-repeat;
}


/*左下*/

.AirQualityGrade {
    position: absolute;
    left: 57px;
    top: 640px;
    width: 603px;
    height: 70px;
    padding-left: 7px;
    padding-right: 7px;
    background-image: url("./res/rectangle4.png");
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 10px;

    font-family: PingFangSC-Semibold, sans-serif;
    font-size: 14px;
    color: #FFFFFF;
}
.QualityGradeUl{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex:1;
    padding-left:25px;
    padding-right:25px;
}
.QualityGradeLi{
    display:flex;
    flex-direction: row;
    align-items: center;
}

.QualitySquare{
    width: 14px;
    height: 14px;
    margin-right: 4px;
    border-radius: 2px;
}
.QualityDot{
    width: 12px;
    height: 12px;
    margin-right: 8px;
    border:solid 3px #fff;
    border-radius: 50%;
}

.SingleRoomBoardHr {
    background-image: linear-gradient(90deg, #0A2157 0%, #10305E 6%, #337983 51%, #0A2358 93%, #0A1E55 100%);
    height: 2px;
    width:580px;
    position: absolute;
    top:616px;
    left:75px;
}