.GreenRectAnim {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.GreenRectAnimLi {
    background: #00FFD8;
    width: 7px;
    height: 16px;
    margin-right: 5px;

    /*animation: GreenAnim infinite 20s linear;*/

}
@keyframes GreenAnim {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}
