.BarChart {
    height: 6px;
    width: 232px;
    margin:auto;
    position: relative;
}

.BarTrack {
    height: 6px;
    border-radius: 3px;
    width:100%;

}
.BarBar {
    height: 6px;
    border-radius: 3px;
    position: absolute;
    top:0;left:0;
}